// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  settings: {
    auth : {
      wso2 : '',
      token : '',
      logout : '',
      mfa_validar_uri : '',
      mfa_reenviar_uri : '',
      timeLimitForRefresh: 500
    },
    headers : {
      token_type : 'Bearer',
      content_type_urlencoded : '',
      content_type_json : ''
    },
    captcha : {
      chave: '0x4AAAAAAAwheNi5iube4VWK'
    },
    api : '',
    portal : 'FO',
    tipoUsuario: 'FO',
    wso2_token_required: false,
    path: '/',
    secure_flag: true,
    usuarioRobo: 'FO',
    acessoprc: '',
    central_ajuda: {
      uri: '',
      enabled: true
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

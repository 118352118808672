export const environment = {
  production: false,
  settings: {
    auth: {
      wso2: 'https://clientes.quod.com.br/autg/token',
      token: 'https://clientes.quod.com.br/apig/negativacao/v1/autenticacoes',
      logout: 'https://clientes.quod.com.br/apig/negativacao/v1/autenticacoes/desconectar',
      timeLimitForRefresh: 500,
      mfa_validar_uri: 'https://clientes.quod.com.br/apig/negativacao/v1/mfa-validar',
      mfa_reenviar_uri: 'https://clientes.quod.com.br/apig/negativacao/v1/mfa-reenviar'
    },
    headers: {
      token_type: 'Bearer ',
      content_type_urlencoded: 'application/x-www-form-urlencoded',
      content_type_json: 'application/json;charset=UTF-8'
    },
    captcha : {
      chave: '0x4AAAAAAAwlOuSVwl2UDN53'
    },
    api : 'https://clientes.quod.com.br/apig/negativacao/v1',
    portal : 'FO',
    tipoUsuario: 'FO',
    wso2_token_required: false,
    secure_flag: true,
    usuarioRobo : 'RO',
    acessoprc : 'prod',
    path: '/',
    central_ajuda: {
      uri: 'https://clientes.quod.com.br/ajuda/?access_token='
    }
  }
}

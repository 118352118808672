// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  settings: {
    auth : {
      wso2 : 'https://apisecurity.evoluzti.com/token',
      token : 'http://localhost:9093/negativacao/v1/autenticacoes',
      logout : 'http://localhost:9093/negativacao/v1/autenticacoes/desconectar',
      mfa_validar_uri : 'http://localhost:9093/negativacao/v1/mfa-validar',
      mfa_reenviar_uri : 'http://localhost:9093/negativacao/v1/mfa-reenviar',
      timeLimitForRefresh: 500
    },
    headers : {
      token_type : 'Bearer ',
      content_type_urlencoded : 'application/x-www-form-urlencoded',
      content_type_json : 'application/json;charset=UTF-8'
    },
    captcha : {
      chave: '0x4AAAAAAAwheNi5iube4VWK'
    },
    api : 'http://localhost:9093/negativacao/v1',
    portal : 'FO',
    tipoUsuario: 'FO',
    wso2_token_required: false,
    path: '/',
    secure_flag: false,
    usuarioRobo: 'RO',
    acessoprc:'prod',
    central_ajuda: {
      uri: 'http://d.ajuda.controlcred.com.br?access_token=',
      enabled: true
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

